import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/login',
		method: 'post',
		data: data,
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: "/logout",
		method: "get",
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}


AuthService.forgotPassword = function (data) {
	return fetch({
		url: '/forgot-password',
		method: 'post',
		data: data
	})
}

AuthService.resetPassword = function (data) {
	return fetch({
		url: '/reset-password',
		method: 'post',
		data: data
	})
}

AuthService.changePassword = function (data) {
	return fetch({
		url: '/password',
		method: 'put',
		data: data
	})
}



export default AuthService;